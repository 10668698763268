import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    DropdownProps,
    Field,
    InfoLabel,
    MessageBar,
    MessageBarBody,
    Option,
} from "@fluentui/react-components";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { RagMode, RagModeOption, ragModes } from "../models/const";
import { pipeline } from "../models/pipeline";
import FileCustom from "./FileCustom";
import { canBeAnalyzed, isAnalyzed } from "./FileList";
import {
    DriveItemWithStatus,
    getFilesAndChildren,
} from "./FileListModalAnalyze";
import Loading from "./Loading";
import VirtualScroller from "./VirtualScroller";

type Props = {
    startMag: (files: DriveItem[]) => void;
    selectedItems: DriveItem[];
    setError: (err: string) => void;
    fetchPipelines: (itemIds: DriveItem[]) => Promise<pipeline[]>;
    setRagMode: (ragMode: RagMode) => void;
    ragMode: RagModeOption;
};

const maxWithoutTextSearch = 10;

export default function FileListModalRag({
    startMag,
    selectedItems: selectedItemsIn,
    setError,
    fetchPipelines,
    setRagMode,
    ragMode,
}: Props) {
    const [modalFiles, setModalFiles] = useState<DriveItemWithStatus[]>([]);
    const [loading, setLoading] = useState(true);
    const [pipelines, setPipelines] = useState<pipeline[]>([]);
    const [notAnalyzedCount, setNotAnalyzedCount] = useState<number>(0);

    useEffect(() => {
        fetchFiles();
    }, []);

    let nbFilesSelected = modalFiles.filter((file) => file.checked).length;

    const mustDoTextSearch = (nbFiles: number) =>
        !nbFiles || nbFiles > maxWithoutTextSearch;

    let startButtonDisabled =
        loading || (!ragMode.value && mustDoTextSearch(nbFilesSelected));

    const fetchFiles = async () => {
        setLoading(true);

        let { files, error } = await getFilesAndChildren(selectedItemsIn);

        if (error) {
            setError(error.message);
            return;
        }

        let gotPipelines = await fetchPipelines(files);

        setPipelines(gotPipelines);

        let analyzedFiles: DriveItemWithStatus[] = files
            .filter((item) => isAnalyzed(item, gotPipelines))
            .map((item) => ({
                checked: true,
                driveItem: item,
            }));

        setNotAnalyzedCount(
            files.filter((item) => canBeAnalyzed(item, gotPipelines)).length
        );

        setModalFiles(analyzedFiles);

        setRagMode(mustDoTextSearch(analyzedFiles.length) ? "LAST" : undefined);

        setLoading(false);
    };

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setModalFiles(
            modalFiles.map((itemIn) => ({
                ...itemIn,
                checked:
                    itemIn.driveItem.id === item.id
                        ? !itemIn.checked
                        : itemIn.checked,
            }))
        );
    };

    const handleStart = () => {
        startMag(
            modalFiles.filter((file) => file.checked).map((el) => el.driveItem)
        );
    };

    const onOptionSelect: DropdownProps["onOptionSelect"] = (ev, data) => {
        const selectedValue = ragModes.find(
            (rm) => rm.label === data.optionValue
        )?.value;

        setRagMode(selectedValue);
    };

    const jsxFileList = (items: DriveItemWithStatus[]) =>
        items.map((item) => (
            <FileCustom
                file={item.driveItem}
                checked={item.checked}
                checkChange={handleCheckChange}
                onClick={() => {}}
                key={item.driveItem.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.driveItem.id
                )}
                selected={false}
            ></FileCustom>
        ));

    const jsxNotAnalyzedWarning =
        notAnalyzedCount !== 0 ? (
            <div style={{ marginBottom: "15px" }}>
                <MessageBar>
                    <MessageBarBody>
                        {notAnalyzedCount}{" "}
                        {notAnalyzedCount === 1
                            ? " selected file is"
                            : " selected files are"}{" "}
                        not analyzed yet. Do this first to include{" "}
                        {notAnalyzedCount === 1 ? "it" : "them"} in MAG as well.
                    </MessageBarBody>
                </MessageBar>
            </div>
        ) : (
            <></>
        );

    const jsxAccordion = (
        <Accordion collapsible multiple>
            <AccordionItem value="1">
                <AccordionHeader>Settings</AccordionHeader>
                <AccordionPanel>
                    <Field
                        validationState="none"
                        label={
                            <InfoLabel
                                info={
                                    <div>
                                        Text search automatically selects
                                        documents relevant to your chat
                                        message(s).
                                        <br />
                                        Must be enabled if 0 or more than 10
                                        documents are selected.
                                    </div>
                                }
                            >
                                Text search
                            </InfoLabel>
                        }
                    >
                        <Dropdown
                            value={ragMode.label}
                            selectedOptions={[ragMode.label]}
                            onOptionSelect={onOptionSelect}
                        >
                            {ragModes.map((rm) => (
                                <Option
                                    key={rm.label}
                                    value={rm.label}
                                    disabled={
                                        !rm.value &&
                                        mustDoTextSearch(nbFilesSelected)
                                    }
                                >
                                    {rm.label}
                                </Option>
                            ))}
                        </Dropdown>
                    </Field>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );

    return (
        <DialogSurface>
            <DialogBody>
                <DialogTitle>MAG Chat</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <div style={{ height: "60px" }}>
                            <Loading />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                            }}
                        >
                            {!!modalFiles.length && jsxNotAnalyzedWarning}
                            <div style={{ marginBottom: "15px" }}>
                                {jsxAccordion}
                            </div>
                            <VirtualScroller
                                hasMore={false}
                                jsxItems={jsxFileList(modalFiles)}
                                loadNextPage={() => {}}
                                loadingMore={false}
                                nbLoadedFiled={modalFiles.length}
                            ></VirtualScroller>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button
                        appearance="primary"
                        disabled={startButtonDisabled}
                        onClick={handleStart}
                    >
                        Open Chat{" "}
                        {!startButtonDisabled && !!nbFilesSelected ? (
                            <>({nbFilesSelected})</>
                        ) : (
                            <></>
                        )}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    );
}
