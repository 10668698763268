import { Checkbox, teamsLightTheme, tokens } from "@fluentui/react-components";
import {
    CheckmarkCircle20Filled,
    Clock20Filled,
    DismissCircle20Filled,
} from "@fluentui/react-icons";
import { File } from "@microsoft/mgt-react";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useTeams } from "@microsoft/teamsfx-react";
import React from "react";
import {
    color,
    isRunningLikeStatus,
    pipeline,
    status,
} from "../models/pipeline";
import { hasCheckBox } from "./FileList";

type Props = {
    file: DriveItem;
    checked: boolean;
    checkChange: (teamsId: DriveItem, checked: boolean) => void;
    onClick: (file: DriveItem) => void;
    pipeline: pipeline | undefined;
    selected: boolean;
};

const FileCustom: React.FC<Props> = ({
    file,
    checked,
    checkChange,
    onClick,
    pipeline,
    selected,
}) => {
    const [theme] = useTeams({});
    const fileListTheme = theme.theme === teamsLightTheme ? "" : "mgt-dark";

    const statusIcon = !pipeline ? (
        <></>
    ) : pipeline.status === status.SUCCESS ? (
        <CheckmarkCircle20Filled color={color.SUCCESS} />
    ) : pipeline.status === status.FAILED ? (
        errorIcon
    ) : isRunningLikeStatus(pipeline.status) ? (
        <Clock20Filled color={color.RUNNING} />
    ) : (
        <></>
    );

    const selectedColor = selected ? "black" : "";

    return (
        <div
            style={{
                display: "flex",
                backgroundColor:
                    theme.theme === teamsLightTheme
                        ? tokens.colorNeutralBackground2
                        : tokens.colorNeutralStrokeDisabled,
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        backgroundColor: selectedColor,
                        width: "5px",
                        marginLeft: "2px",
                        height: "30px",
                        borderRadius: "5px",
                    }}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "24px",
                }}
            >
                <Checkbox
                    checked={checked}
                    onChange={(ev, data) => {
                        checkChange(
                            file,
                            data.checked !== "mixed" ? data.checked : false
                        );
                    }}
                    disabled={!hasCheckBox(file)}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "24px",
                    marginLeft: "5px",
                }}
            >
                {statusIcon}
            </div>

            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                    cursor: "pointer",
                }}
                onClick={() => onClick(file)}
            >
                <File
                    className={fileListTheme + " hover-file"}
                    driveItem={file}
                />
            </div>
        </div>
    );
};

export default FileCustom;

export const errorIcon = <DismissCircle20Filled color={color.FAILED} />;
