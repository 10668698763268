import { Button, Tooltip } from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import { app } from "@microsoft/teams-js";
import { useState } from "react";
import Settings from "./Settings";

type Props = {
    context: app.Context | undefined;
    tenant: string;
    setTenant: (tenant: string) => void;
    view: string;
    setView: (view: string) => void;
    loading: boolean;
    filterActive: boolean;
    setFilterActive: (loading: boolean) => void;
};

export default function Header({
    setTenant,
    tenant,
    context,
    view,
    setView,
    loading,
    filterActive,
    setFilterActive,
}: Props) {
    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            <div className="title">
                <h2>Document Analysis</h2>
                <div
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        display: "flex",
                    }}
                >
                    <Tooltip content="Settings" relationship="label">
                        <Button
                            onClick={() => {
                                setShowSettings(true);
                            }}
                            icon={<SettingsRegular />}
                            size="small"
                            appearance="subtle"
                        />
                    </Tooltip>
                </div>
            </div>

            <Settings
                context={context}
                relevantOnly={filterActive}
                loading={loading}
                setFilterActive={setFilterActive}
                setTenant={setTenant}
                setView={setView}
                tenant={tenant}
                view={view}
                setShow={setShowSettings}
                show={showSettings}
            ></Settings>
        </>
    );
}
