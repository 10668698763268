import { tokens } from "@fluentui/react-components";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { app } from "@microsoft/teams-js";
import {
    GetPipelinesList,
    Instance,
    InstanceElements,
} from "../services/openapi/pipeline-api-axios/Api";

export const actionLabelTeamGroupId = "team_group_id";
export const actionLabelUserId = "user_id";

export type pipeline = {
    teamsId: string;
    clobId?: string;
    pipelineId: string;
    status: string;
};

export const status = {
    RUNNING: "RUNNING",
    QUEUED: "QUEUED",
    QUEUED_LOCAL: "QUEUED_LOCAL",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    CANCELED: "CANCELED",
    PAUSED: "PAUSED",
};

export const color = {
    RUNNING: tokens.colorNeutralForeground2BrandHover,
    SUCCESS: "#57C4AD",
    FAILED: "#DB4325",
};

interface MimeTypeMapping {
    [mimeType: string]: string;
}

export const mimeTypeToDynizerType: MimeTypeMapping = {
    "application/pdf": "pdf",
    "text/plain": "txt",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
    "application/msword": "doc",
    "text/rtf": "rtf",
    "application/rtf": "rtf",
    "message/rfc822": "eml",
    "application/vnd.ms-outlook": "msg",
    "application/vnd.oasis.opendocument.text": "odt",
};

export function allowedFileTypes() {
    let out = "";
    let arr = allowedFileTypesArray();
    for (const value of arr) {
        out += value + ", ";
    }

    return out.substring(0, out.length - 2);
}

export function allowedFileTypesArray() {
    let out: any[] = [];
    for (const [key, value] of Object.entries(mimeTypeToDynizerType)) {
        !out.find((el) => value === el) && out.push(value);
    }

    return out;
}

export function isRunningLikeStatus(inStatus: string) {
    return (
        inStatus === status.RUNNING ||
        inStatus === status.QUEUED ||
        inStatus === status.QUEUED_LOCAL ||
        inStatus === status.PAUSED
    );
}

export const buildInstanceElements = (
    actionLabelFileId: string,
    fileId: string,
    file: DriveItem,
    context: app.Context | undefined
): InstanceElements => {
    let groupId = getGroupIdFromContext(context);

    return [
        {
            action_label: actionLabelFileId,
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: [fileId],
        },
        {
            action_label: "teams_file_name",
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: file.name ? [file.name] : [],
        },
        {
            action_label: actionLabelUserId,
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: !groupId && context?.user?.id ? [context.user.id] : [],
        },
        {
            action_label: actionLabelTeamGroupId,
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: groupId ? [groupId] : [],
        },
        {
            action_label: "team_channel_id",
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: context?.channel?.id ? [context.channel.id] : [],
        },
        {
            action_label: "team_chat_id",
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: context?.chat?.id ? [context.chat.id] : [],
        },
        {
            action_label: "team_meeting_id",
            component: "What",
            default_data_type: "DT_String",
            max_occurence: 1,
            min_occurence: 0,
            specs: [],
            values: context?.meeting?.id ? [context.meeting.id] : [],
        },
    ];
};

// team?.groupId is null for private channels, hence ownerGroupId
export const getGroupIdFromContext = (context: app.Context | undefined) => {
    return context?.team?.groupId || context?.channel?.ownerGroupId;
};

export const apiPipelinesToPipelines = (
    gotPipelines: GetPipelinesList,
    actionLabelTeamsId: string
) => {
    return gotPipelines.map((gotPipeline) => {
        let outPipeline: pipeline = {
            status: gotPipeline.info.status,
            teamsId: "",
            clobId: "",
            pipelineId: gotPipeline.id,
        };

        if (!gotPipeline.configuration?.instance) return outPipeline;

        let teamsId = readValueFromInstance(
            gotPipeline.configuration?.instance,
            actionLabelTeamsId
        );

        outPipeline.teamsId = teamsId;

        return outPipeline;
    });
};

const readValueFromInstance = (instance: Instance, actionLabelIn: string) => {
    let instanceElements = instance?.instance_elements;

    if (!instanceElements || instanceElements.length === 0) {
        return "";
    }

    let instanceElementIndex = instanceElements.findIndex(
        (instanceElement) => instanceElement.action_label === actionLabelIn
    );

    if (instanceElementIndex === -1) {
        return "";
    }

    return instanceElements[instanceElementIndex].values[0] || "";
};
