import {
    Link,
    ProgressBar,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    ToastTrigger,
    useId,
    useToastController,
} from "@fluentui/react-components";
import { useEffect } from "react";

type Props = {
    value: number;
    max: number;
    uploading: boolean;
};

export const Progress = ({ value, max, uploading }: Props) => {
    const toasterId = useId("toaster");
    const toastId = useId("toast");
    const { dispatchToast, updateToast } = useToastController(toasterId);

    useEffect(() => {
        if (uploading) {
            dispatchToast(jsxToastProgress(value, max), {
                toastId,
                timeout: -1,
                intent: "info",
            });
        }
    }, [uploading]);

    useEffect(() => {
        updateToast(
            value === max
                ? {
                      toastId,
                      content: jsxToastSuccess(value, max),
                      intent: "success",
                  }
                : {
                      toastId,
                      content: jsxToastProgress(value, max),
                      intent: "info",
                  }
        );
    }, [value, max]);

    const jsxToastProgress = (value: number, max: number) => {
        return (
            <Toast>
                <ToastTitle>
                    Starting Analyses ({value}/{max})
                </ToastTitle>
                <ToastBody>
                    <ProgressBar value={value} max={max} />
                </ToastBody>
            </Toast>
        );
    };

    const jsxToastSuccess = (value: number, max: number) => {
        return (
            <Toast>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >
                    Started {max} analyses
                </ToastTitle>
            </Toast>
        );
    };

    return (
        <>
            <Toaster toasterId={toasterId} />
        </>
    );
};
