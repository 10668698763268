export const actionName = "raw_json";
export const actionLabelDocument = "document";
export const actionLabelTeamsDocumentId = "teams_doc_id";
export const pseudoPluginAction = "document_pseudonymize";
export const pseudoPluginActionLabel = "pseudonymization";

export const errorColor = "red";
export const successColor = "green";

export const ragModes: RagModeOption[] = [
    { label: "No text search", value: undefined },
    { label: "Based on last chat message", value: "LAST" },
    { label: "Based on first chat message", value: "FIRST" },
    { label: "Based on all chat messages", value: "ALL" },
];

export type RagModeOption = {
    label: string;
    value: RagMode;
};

export type RagMode = "LAST" | "FIRST" | "ALL" | undefined;
