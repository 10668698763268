import { Button } from "@fluentui/react-components";
import { Icon } from "@iconify/react";
import { Providers } from "@microsoft/mgt-element";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";

type Props = {
    inJson: any;
    file: DriveItem;
    setError: (msg: string) => void;
    setSuccess: (title: string, msg: string) => void;
};

const Pseudo: React.FC<Props> = ({ inJson, file, setError, setSuccess }) => {
    const [loading, setLoading] = useState(false);

    const refElm = useRef<any>(null);

    useEffect(() => {
        if (inJson) {
            let data = fixNullList(inJson);
            refElm.current.data = data;
        }
    }, [inJson]);

    const downloadPDF = () => {
        const pseudoText = getPseudoText(inJson);

        let doc = buildPDF(pseudoText);

        doc.save("document.pdf");
    };

    const storePDF = async () => {
        const pseudoText = getPseudoText(inJson);

        let doc = buildPDF(pseudoText);

        const pdfBlob = doc.output("blob");

        let siteID = file.parentReference?.siteId || "";
        let folderID = file.parentReference?.id || "";

        try {
            let newFileName = anonymizedFileName(file.name || "");

            let uploadResponse = await uploadBlobIfNotExists(
                pdfBlob,
                siteID,
                folderID,
                newFileName
            );

            if (uploadResponse) {
                setSuccess("Created  document '" + newFileName + "'", "");
            }
        } catch (err: any) {
            setError("Error uploading file: " + err.message);
            return;
        }
    };

    const uploadBlobIfNotExists = async (
        blob: Blob,
        siteID: string,
        folderID: string,
        filename: string
    ): Promise<DriveItem | undefined> => {
        try {
            setLoading(true);

            // Check if the file already exists
            const checkFileResponse =
                await Providers.globalProvider.graph.client
                    .api(
                        `/sites/${siteID}/drive/items/${folderID}:/${filename}`
                    )
                    .get();

            if (checkFileResponse) {
                setError("'" + filename + "' already exists");
            }
        } catch (error: any) {
            if (error.statusCode === 404) {
                // File doesn't exist, proceed with the upload
                const uploadResponse: DriveItem =
                    await Providers.globalProvider.graph.client
                        .api(
                            `/sites/${siteID}/drive/items/${folderID}:/${filename}:/content`
                        )
                        .put(blob);

                return uploadResponse;
            } else {
                // Handle other errors
                throw new Error(
                    `Error checking file existence: ${error.message}`
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const removeExtension = (filename: string): string => {
        return filename.split(".").slice(0, -1).join(".");
    };

    const anonymizedFileName = (oldFileName: string) => {
        let baseName = removeExtension(oldFileName);
        return baseName + "_anonymized.pdf";
    };

    return (
        <div>
            <consono-pseudonymize-document
                ref={refElm}
            ></consono-pseudonymize-document>

            <Button
                onClick={() => {
                    storePDF();
                }}
                className="mt-1 mr-1 mb-1"
                icon={<Icon icon="mdi:upload" />}
                disabled={loading}
            >
                Store in '{file.parentReference?.name}'
            </Button>

            <Button
                onClick={() => {
                    downloadPDF();
                }}
                className="mt-1 mr-1 mb-1 ml-2"
                icon={<Icon icon="mdi:download" />}
            >
                Download
            </Button>
        </div>
    );
};
export default Pseudo;

const fixNullList = (inJson: any) => {
    let data = JSON.parse(inJson);
    if (!data["pseudo_value_lists"]) {
        data["pseudo_value_lists"] = [];
    }
    return data;
};

const getPseudoText = (inJson: any): string => {
    let data = JSON.parse(inJson);
    return data["pseudo_text"];
};

const buildPDF = (text: string) => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height; // Height of a PDF page
    const marginTop = 20;
    const marginBottom = 10;
    const lineHeight = 7;
    const marginLeft = 14;
    const maxLineWidth = 280;
    const fontSize = 10;
    const textLines = doc.splitTextToSize(text, maxLineWidth); // Automatically split text into lines

    let cursorY = marginTop;

    doc.setFontSize(fontSize);

    textLines.forEach((line: any) => {
        if (cursorY + lineHeight > pageHeight - marginBottom) {
            doc.addPage(); // Add a new page when content exceeds the page height
            cursorY = marginTop; // Reset Y cursor to the top for the new page
        }
        doc.text(line, marginLeft, cursorY);
        cursorY += lineHeight;
    });

    return doc;
};
