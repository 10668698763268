import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dropdown,
    DropdownProps,
    Field,
    Input,
    InputOnChangeData,
    makeStyles,
    Option,
    shorthands,
    Tooltip,
    useId,
} from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { errorColor, successColor } from "../models/const";
import { allowedFileTypes, getGroupIdFromContext } from "../models/pipeline";
import { checkTenant } from "../services/dynizer/dynizer";
import { tenantNameTrimmed } from "./Tab";

type Props = {
    tenant: string;
    context: app.Context | undefined;
    relevantOnly: boolean;
    setFilterActive: (loading: boolean) => void;
    loading: boolean;
    view: string;
    setView: (view: string) => void;
    setTenant: (tenant: string) => void;
    show: boolean;
    setShow: (loading: boolean) => void;
};

const Settings = ({
    tenant,
    context,
    relevantOnly: filterActive,
    setFilterActive,
    loading,
    view,
    setView,
    setTenant,
    show,
    setShow,
}: Props) => {
    const [tenantInput, setTenantInput] = useState(tenant);
    const [checkingTenant, setCheckingTenant] = useState(false);
    const [message, setMessage] = useState("");
    const [color, setColor] = useState(errorColor);

    useEffect(() => {
        if (show) {
            setTenantInput(tenant);
            setMessage("");
        }
    }, [show]);

    const styles = useStyles();

    const dropdownId = useId("dropdown-default");

    const checkValidTenant = async () => {
        setCheckingTenant(true);
        setMessage("");

        let tenantInputTrimmed = tenantNameTrimmed(tenantInput);

        let ok = await checkTenant(tenantInputTrimmed);

        if (ok) {
            setTenant(tenantInputTrimmed);
            setTenantInput(tenantInputTrimmed);
            setColor(successColor);
            setMessage(`Connected to tenant '${tenantInputTrimmed}'`);
        } else {
            setColor(errorColor);
            setMessage(`Cannot reach tenant '${tenantInputTrimmed}'`);
        }

        setCheckingTenant(false);
    };

    const handleFilterChange = () => {
        setFilterActive(!filterActive);
    };

    const onTenantChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData
    ) => {
        setTenantInput(data.value);
    };

    const onViewSelect: DropdownProps["onOptionSelect"] = (ev, data) => {
        let newView = data.optionText;

        if (newView && newView !== view) {
            setView(newView);
        }
    };

    const tenantChanged = () => {
        return tenantNameTrimmed(tenantInput) !== tenant;
    };

    const tenantInvalid = () => {
        return !tenantInput || tenantInput !== tenantInput.trim();
    };

    const disableSave = checkingTenant || !tenantChanged() || tenantInvalid();

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!disableSave && e.key === "Enter") {
            e.preventDefault();

            checkValidTenant();
        }
    };

    const [contextField, contextInfo] = context
        ? getGroupIdFromContext(context)
            ? [`Team group id`, getGroupIdFromContext(context)]
            : context.channel?.id
            ? [`Channel id`, context.channel.id]
            : context.chat?.id
            ? [`Chat id`, context.chat.id]
            : context.meeting?.id
            ? [`Meeting id`, context.meeting.id]
            : context.user?.id
            ? [`User id`, context.user.id]
            : ["", ""]
        : ["", ""];

    return (
        <>
            <Dialog
                open={show}
                onOpenChange={(event, data) => setShow(data.open)}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Settings</DialogTitle>
                        <DialogContent>
                            <div className={styles.form}>
                                <Tooltip
                                    relationship="description"
                                    content={`Check to only show files that can be processed by Dynizer: ${allowedFileTypes()}`}
                                >
                                    <Checkbox
                                        checked={filterActive}
                                        label={"Show relevant files only"}
                                        onChange={handleFilterChange}
                                        defaultChecked={true}
                                        style={{ marginLeft: "-7px" }}
                                    />
                                </Tooltip>
                                {getGroupIdFromContext(context) && (
                                    <Field
                                        label="File view"
                                        validationState="none"
                                    >
                                        <Dropdown
                                            aria-labelledby={dropdownId}
                                            placeholder="Select file source"
                                            selectedOptions={[view]}
                                            onOptionSelect={onViewSelect}
                                            value={view}
                                            disabled={checkingTenant || loading}
                                        >
                                            {views.map((option) => (
                                                <Option key={option}>
                                                    {option}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </Field>
                                )}
                                <Field
                                    label={contextField}
                                    validationState="none"
                                >
                                    <Input
                                        value={contextInfo}
                                        disabled={true}
                                    />
                                </Field>
                                <Field label="Dynizer" validationState="none">
                                    <Input
                                        value={tenantInput}
                                        onChange={onTenantChange}
                                        onKeyDown={onKeyDown}
                                    />
                                </Field>
                            </div>
                            <div
                                style={{ color: color }}
                                className="pre-wrap mt-2"
                            >
                                {message}
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Close</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    checkValidTenant();
                                }}
                                disabled={disableSave}
                            >
                                Save Changes
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

export default Settings;

const useStyles = makeStyles({
    form: {
        maxWidth: "400px",
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
    },
});

export const channelView = "Channels";
export const siteView = "Site";

const views = [siteView, channelView];
