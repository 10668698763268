/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Limits the amount of records to return. */
export type Limit = number;

/** Offsets the records to return. */
export type Offset = number;

/** ID of an object within the collection. */
export type ID = string;

/** Full URI string. */
export type URI = string;

/** Total number of records available within a collection query. */
export type Total = number;

/** The message to send as prompt in the chat or generate request. */
export type Text = string;

/**
 * The RAG type to use.
 * Omit this parameter to add no RAG. Your prompt will be directly forwarded.
 * Possible values:
 *   - ANNOTATE => Add WWWW information
 *   - TEXT => Send text only
 */
export type RagType = string;

/**
 * The RAG mode to use.
 * Possible values:
 *   - FIRST => Search documents based only on the first message
 *   - LAST => Search documents based only on the last message
 *   - ALL => Search documents based on all messages
 * @default "FIRST"
 */
export type RagMode = string;

/**
 * How much of the summarized content to send as RAG.
 * @format int32
 */
export type SummaryPct = number;

/** The ID to keep track of the conversation. */
export type ConversationID = string;

/** A message that was sent in the chat. */
export type Message = string;

/** The owner of a message (USER or CHATBOT). */
export type Role = string;

/** N/A */
export type ToolCalls = string;

/** The RAG content set as a preamble for each question. */
export type Preamble = string;

/**
 * The response maximum amount of tokens.
 * @default 1000
 */
export type MaxTokens = number;

/**
 * Controls the randomness of the generated text.
 * Needs to be between 0-1.
 * ~0 = more deterministic and focused outputs.
 * ~1 = more creative and diverse outputs.
 * @default 0.5
 */
export type Temperature = number;

/**
 * Controls the repetition of tokens (words or phrases) in the generated text.
 * Needs to be between 0-1.
 * ~0 = Minimal or no penalty is applied.
 * ~1 = A strong penalty is applied to repeated words.
 * @default 0.5
 */
export type FrequencyPenalty = number;

/**
 * Controls the diversity of the generated text.
 * It does this by considering only the most probable next words, up to a certain cumulative probability, for sampling.
 * Needs to be between 0-1.
 * ~0 = Fewer words are considered, leading to more focused and deterministic outputs.
 * ~1 = More words are considered, leading to more diverse outputs.
 * @default 0.5
 */
export type TopP = number;

/**
 * Controls the diversity of the generated text by limiting the sampling pool to the top k most probable next words.
 * This parameter is used to control the trade-off between diversity and coherence in the generated text.
 * Needs to be greater or equal than 0.
 * ~10 = The model is restricted to the very top probable tokens, making the text more deterministic and focused.
 * ~100 = The model considers a larger set of tokens, introducing more variability and potential creativity.
 * @default 10
 */
export type TopK = number;

/**
 * Specifies which pre-trained language model you want to use for generating text.
 * @default "cohere.command"
 */
export type ModelID = string;

/**
 * The language to use for prompts and responses.
 * When this parameter is not provided the system will try to detect the prompt language.
 * Supported languages:
 *   - en
 *   - nl
 */
export type Language = string;

/** The Dynizer API to use for RAG. */
export type APIURL = string;

/** If this flag is set, the API will not prompt the LLM */
export type NoPrompt = string;

/**
 * Parameters to search the documents for RAG.
 *
 * - The `operation` property defines how elements in `filter` are combined. Defaults to 'OR' if omitted.
 * - If `filter` is empty or `text_search` is set to true, DQL's text_search() function is used.
 */
export interface DocumentSelector {
  /** Dynizer action to use in a DocumentSelector */
  action?: Action;
  /** Dynizer action label to use in a DocumentSelector or DocumentFilter. */
  action_label?: ActionLabel;
  /** Dynizer action label to use in a DocumentSelector or DocumentFilter. */
  action_label_id?: ActionLabel;
  /** List of filter items to filter the RAG documents */
  filter?: DocumentFilter;
  /** Operation between filters, must be 'AND' or 'OR' */
  operation?: Operation;
  /** Indicates whether or not to use DQL's text_search() function in a DocumentSelector */
  text_search?: TextSearch;
}

/** Parameters to select sentences from returned documents */
export type SentenceSelector = SentenceSelectorItem[];

/** Parameters to select sentences from a document */
export interface SentenceSelectorItem {
  /** Unique reference ID that points to a document */
  document_id?: DocumentID;
  /** Sentence IDs to use from the document */
  sentences?: SentenceIDList;
}

/** Unique reference ID that points to a document */
export type DocumentID = string;

/** Sentence IDs to use from the document */
export type SentenceIDList = SentenceID[];

/** Sentence ID to use from the document */
export type SentenceID = number;

/** Dynizer action to use in a DocumentSelector */
export type Action = string;

/** Dynizer action label to use in a DocumentSelector or DocumentFilter. */
export type ActionLabel = string;

/** Value to filter the RAG documents. */
export type Value = string;

/**
 * Name of a function that takes no arguments and returns one value, e.g. 'current_user'.
 * Used to filter the RAG documents.
 */
export type ValueFunction = string;

/**
 * Name of a function that takes no arguments and might return more than one row., e.g. 'current_microsoft_user_teams'.
 * Used to filter the RAG documents.
 */
export type SetReturningFunction = string;

/** Indicates whether or not to use DQL's text_search() function in a DocumentSelector */
export type TextSearch = boolean;

/** Operation between filters, must be 'AND' or 'OR' */
export type Operation = string;

/** List of filter items to filter the RAG documents */
export type DocumentFilter = DocumentFilterItem[];

/**
 * A filter item.
 *
 * Use one of these property combinations:
 * - action_label  + value                   -> WHERE {action_label} = '{value}'
 * - action_label  + value_function          -> WHERE {action_label} = {value_function}()
 * - action_label  + set_returning_function  -> WHERE {action_label} IN (SELECT {set_returning_function}())
 * - filter        + operation (AND/OR)      -> WHERE ({filter1}) {operation} ({filter2}) {operation} ({filter3}) ...
 */
export interface DocumentFilterItem {
  /** Dynizer action label to use in a DocumentSelector or DocumentFilter. */
  action_label?: ActionLabel;
  /** Value to filter the RAG documents. */
  value?: Value;
  /**
   * Name of a function that takes no arguments and returns one value, e.g. 'current_user'.
   * Used to filter the RAG documents.
   */
  value_function?: ValueFunction;
  /**
   * Name of a function that takes no arguments and might return more than one row., e.g. 'current_microsoft_user_teams'.
   * Used to filter the RAG documents.
   */
  set_returning_function?: SetReturningFunction;
  /** List of filter items to filter the RAG documents */
  filter?: DocumentFilter;
  /** Operation between filters, must be 'AND' or 'OR' */
  operation?: Operation;
}

/** Generate properties. */
export interface Generate {
  /** The message to send as prompt in the chat or generate request. */
  text: Text;
  /**
   * The RAG type to use.
   * Omit this parameter to add no RAG. Your prompt will be directly forwarded.
   * Possible values:
   *   - ANNOTATE => Add WWWW information
   *   - TEXT => Send text only
   */
  rag_type?: RagType;
  /** How much of the summarized content to send as RAG. */
  summary_pct?: SummaryPct;
  /** The response maximum amount of tokens. */
  max_tokens?: MaxTokens;
  /**
   * Controls the randomness of the generated text.
   * Needs to be between 0-1.
   * ~0 = more deterministic and focused outputs.
   * ~1 = more creative and diverse outputs.
   */
  temperature?: Temperature;
  /**
   * Controls the repetition of tokens (words or phrases) in the generated text.
   * Needs to be between 0-1.
   * ~0 = Minimal or no penalty is applied.
   * ~1 = A strong penalty is applied to repeated words.
   */
  frequency_penalty?: FrequencyPenalty;
  /**
   * Controls the diversity of the generated text.
   * It does this by considering only the most probable next words, up to a certain cumulative probability, for sampling.
   * Needs to be between 0-1.
   * ~0 = Fewer words are considered, leading to more focused and deterministic outputs.
   * ~1 = More words are considered, leading to more diverse outputs.
   */
  top_p?: TopP;
  /**
   * Controls the diversity of the generated text by limiting the sampling pool to the top k most probable next words.
   * This parameter is used to control the trade-off between diversity and coherence in the generated text.
   * Needs to be greater or equal than 0.
   * ~10 = The model is restricted to the very top probable tokens, making the text more deterministic and focused.
   * ~100 = The model considers a larger set of tokens, introducing more variability and potential creativity.
   */
  top_k?: TopK;
  /** Specifies which pre-trained language model you want to use for generating text. */
  model_id?: ModelID;
  /**
   * Parameters to search the documents for RAG.
   *
   * - The `operation` property defines how elements in `filter` are combined. Defaults to 'OR' if omitted.
   * - If `filter` is empty or `text_search` is set to true, DQL's text_search() function is used.
   */
  document_selector?: DocumentSelector;
  /**
   * The language to use for prompts and responses.
   * When this parameter is not provided the system will try to detect the prompt language.
   * Supported languages:
   *   - en
   *   - nl
   */
  language?: Language;
  /** The Dynizer API to use for RAG. */
  api_url?: APIURL;
}

/** Chat properties. */
export interface Chat {
  /** The message to send as prompt in the chat or generate request. */
  text: Text;
  /**
   * The RAG type to use.
   * Omit this parameter to add no RAG. Your prompt will be directly forwarded.
   * Possible values:
   *   - ANNOTATE => Add WWWW information
   *   - TEXT => Send text only
   */
  rag_type?: RagType;
  /**
   * The RAG mode to use.
   * Possible values:
   *   - FIRST => Search documents based only on the first message
   *   - LAST => Search documents based only on the last message
   *   - ALL => Search documents based on all messages
   */
  rag_mode?: RagMode;
  /** How much of the summarized content to send as RAG. */
  summary_pct?: SummaryPct;
  /** The ID to keep track of the conversation. */
  conversation_id?: ConversationID;
  /** The response maximum amount of tokens. */
  max_tokens?: MaxTokens;
  /**
   * Controls the randomness of the generated text.
   * Needs to be between 0-1.
   * ~0 = more deterministic and focused outputs.
   * ~1 = more creative and diverse outputs.
   */
  temperature?: Temperature;
  /**
   * Controls the repetition of tokens (words or phrases) in the generated text.
   * Needs to be between 0-1.
   * ~0 = Minimal or no penalty is applied.
   * ~1 = A strong penalty is applied to repeated words.
   */
  frequency_penalty?: FrequencyPenalty;
  /**
   * Controls the diversity of the generated text.
   * It does this by considering only the most probable next words, up to a certain cumulative probability, for sampling.
   * Needs to be between 0-1.
   * ~0 = Fewer words are considered, leading to more focused and deterministic outputs.
   * ~1 = More words are considered, leading to more diverse outputs.
   */
  top_p?: TopP;
  /**
   * Controls the diversity of the generated text by limiting the sampling pool to the top k most probable next words.
   * This parameter is used to control the trade-off between diversity and coherence in the generated text.
   * Needs to be greater or equal than 0.
   * ~10 = The model is restricted to the very top probable tokens, making the text more deterministic and focused.
   * ~100 = The model considers a larger set of tokens, introducing more variability and potential creativity.
   */
  top_k?: TopK;
  /** Specifies which pre-trained language model you want to use for generating text. */
  model_id?: ModelID;
  /**
   * The language to use for prompts and responses.
   * When this parameter is not provided the system will try to detect the prompt language.
   * Supported languages:
   *   - en
   *   - nl
   */
  language?: Language;
  /** The Dynizer API to use for RAG. */
  api_url?: APIURL;
  /** If this flag is set, the API will not prompt the LLM */
  no_prompt?: NoPrompt;
  /**
   * Parameters to search the documents for RAG.
   *
   * - The `operation` property defines how elements in `filter` are combined. Defaults to 'OR' if omitted.
   * - If `filter` is empty or `text_search` is set to true, DQL's text_search() function is used.
   */
  document_selector?: DocumentSelector;
  /** Parameters to select sentences from returned documents */
  sentence_selector?: SentenceSelector;
}

/** ConversationItem properties */
export interface ConversationItem {
  /** A message that was sent in the chat. */
  message?: Message;
  /** The owner of a message (USER or CHATBOT). */
  role?: Role;
  /** N/A */
  tool_calls?: ToolCalls;
}

/** List of conversation items. */
export type ConversationList = ConversationItem[];

/** ConversationItem properties */
export interface Conversation {
  /** List of conversation items. */
  conversation?: ConversationList;
  /** The RAG content set as a preamble for each question. */
  preamble?: Preamble;
}

/** API error. */
export interface Error {
  /** @format int32 */
  code: number;
  message: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Dynizer RAG POC API
 * @version 0.0.1
 * @contact Consono <info@consono.ai> (https://consono.ai)
 *
 * POC of RAG based on Dynizer.
 * Unstable API.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  cohere = {
    /**
     * @description Send a generate post request.
     *
     * @tags Generate
     * @name Generate
     * @request POST:/cohere/generate
     * @secure
     */
    generate: (data: Generate, params: RequestParams = {}) =>
      this.request<Conversation, Error>({
        path: `/cohere/generate`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Send a chat post request.
     *
     * @tags Chat
     * @name Chat
     * @request POST:/cohere/chat
     */
    chat: (data: Chat, params: RequestParams = {}) =>
      this.request<Conversation, Error>({
        path: `/cohere/chat`,
        method: "POST",
        body: data,
        ...params,
      }),
  };
}
